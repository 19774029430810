import React, { useEffect, useRef, useState } from 'react';
import { Formik } from 'formik';
import { object, string } from 'yup';
import Button from '@mui/material/Button';
import { useDispatch } from 'react-redux';
import { loginUser } from 'store/actions/user';
import { FETCH_STATUS } from 'types/types';
import { useLoggedInUser } from 'hooks/useLoggedInUser';
import { renderLabel } from 'utils/renderLabel';
import { WrappedFormField } from 'components/InputText/WrappedInputText';
import { renderLoadingIcon } from 'components/renderLoadingIcon';
import { Box } from '@mui/material';

const validationSchema = object({
    email: string().trim().email('Please enter a valid email address').required('Email is required.'),
    password: string()
        .required('Please specify your password')
        .min(8, 'The password should have at minimum length of 8'),
});

interface FormikValues {
    email: string;
    password: string;
}

export const LoginForm = (): JSX.Element => {
    const [loginStatus, setLoginStatus] = useState<FETCH_STATUS | null>(null);
    const loggedInUser = useLoggedInUser();
    const shouldCloseMenu = useRef(false);
    const dispatch = useDispatch();
    const initialValues = {
        email: '',
        password: '',
    };

    useEffect(() => {
        if (loggedInUser && shouldCloseMenu.current) {
            setLoginStatus(FETCH_STATUS.COMPLETE);
            setTimeout(() => {
                window.location.hash = '';
            }, 1000);
        }
    }, [loggedInUser]);

    const onSubmit = async (values) => {
        try {
            setLoginStatus(FETCH_STATUS.LOADING);
            const user = await dispatch(loginUser(values));
            shouldCloseMenu.current = !!user;
        } catch (err) {
            console.log(err);
            setLoginStatus(FETCH_STATUS.ERROR);
        }
    };

    return (
        <Formik<FormikValues> onSubmit={onSubmit} initialValues={initialValues} validationSchema={validationSchema}>
            {(formik) => {
                return (
                    <form onSubmit={formik.handleSubmit}>
                        <Box py={1}>
                            <WrappedFormField name="email" label={renderLabel('EMAIL_ADDRESS')} />
                        </Box>
                        <Box py={1}>
                            <WrappedFormField name="password" type="password" label={renderLabel('PASSWORD')} />
                        </Box>
                        <Box py={1}>
                            <Button
                                type="submit"
                                size="large"
                                variant="contained"
                                fullWidth
                                startIcon={renderLoadingIcon(loginStatus)}
                            >
                                {renderLabel('LOGIN')}
                            </Button>
                        </Box>
                    </form>
                );
            }}
        </Formik>
    );
};

export default LoginForm;
