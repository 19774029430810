import React from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton, Box } from '@mui/material';
import useRsvpFormToggle from 'components/RsvpForm/useRsvpFormToggle';
import CloseIcon from '@mui/icons-material/Close';
import { LoginForm } from './LoginForm';
import { renderLabel } from 'utils/renderLabel';
import { useSelector } from 'react-redux';
import { getIsMobileEnv } from 'components/AppHeader/selectors';

export default function LoginFormModal() {
    const isMobile = useSelector(getIsMobileEnv);
    const { open, closeForm } = useRsvpFormToggle('login');

    return (
        <Dialog fullScreen={isMobile} fullWidth open={open} onClose={closeForm} maxWidth="xs">
            <DialogTitle>
                <Box
                    fontFamily="var(--body-font-family)"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    {renderLabel('LOGIN')}
                    <IconButton aria-label="close" size="small" onClick={closeForm}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent>
                <LoginForm />
            </DialogContent>
        </Dialog>
    );
}
